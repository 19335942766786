<template>
<div style="width:100%">
    <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="form_cerrado.desistimiento_id" :rules="[rules.requerido]" :items="items.items_desistimientos" item-text="descripcion" item-value="id" label="DESISTIMIENTO" :color="colores.primario"></v-autocomplete>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col cols="12">
          <v-textarea v-model="form_cerrado.resumen" :rules="[rules.requerido]" label="RESUMEN RESPUESTA *" rows="5"></v-textarea>
        </v-col>
    </v-row>

    <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="form_cerrado.favorabilidad_id" :rules="[rules.requerido]" :items="items.items_favorabilidad" item-text="descripcion" item-value="id" label="FAVORABILIDAD *"></v-autocomplete>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <uploadFinal :session="session" :keyform="0" :registro="registro" :items_archivos="items_finales" :urlroute="'upload_cargar_final'" :tipo="'RESPUESTA FINAL'" :colores="colores" @refresh_archivos="refrescar_archivos_final" @refresh_estado_respuesta_final="refrescarestado_respuesta_final"></uploadFinal>
      </v-col>
      <v-col cols="12" md="6">
        <uploadAnexos :session="session" :keyform="0" :registro="registro" :items_archivos="items_anexos" :urlroute="'upload_cargar_anexos'" :tipo="'ANEXOS FINAL'" :colores="colores" @refresh_archivos="refrescar_archivos_anexos"></uploadAnexos>
      </v-col>
    </v-row>
</div>
</template>

<script>

export default {
  name: 'form_qr_cierre_desistimientosComponent',
  components: {
    uploadFinal: () => import('@/components/widgets/uploadFinalComponent.vue'),
    uploadAnexos: () => import('@/components/widgets/uploadAnexosComponent.vue')
  },
  props: ['form_cerrado', 'colores', 'rules', 'items', 'session', 'registro'],
  data: () => ({
    items_finales: [],
    items_anexos: []
  }),
  watch: {
    items: {
      immediate: true,
      handler (newVal, oldVal) {
        this.form_cerrado.desistimiento_id = 819
      }
    }
  },
  methods: {
    refrescar_archivos_final (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_finales.push(data[i])
      }
      this.$emit('respuestaFinalFile', 1)
    },
    refrescar_archivos_anexos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_anexos.push(data[i])
      }
    },
    refrescarestado_respuesta_final (estado) {
      this.$emit('respuestaFinalFile', estado)
    }
  }
}
</script>
